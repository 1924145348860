
import {
  MenuIcon,
  XIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from 'vue-feather-icons';
import Teleport from 'vue2-teleport';
export default {
  props: {
    menuitems: {
      type: Array,
      default: () => [],
    },
    menuitemsStatic: {
      type: Array,
      default: () => [],
    },
    openLocalisationDialog: {
      type: Function,
    },
    lookbook: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      menuOpen: false,
      subMenuMobileOpended: '',
    };
  },
  computed: {
    userSession() {
      return this.$auth.user.Customer;
    },

    categoriesItem() {
      try {
        // const list = this.menuitems.filter((item) => {
        //   return item.mobile === false;
        // });

        return this.menuitems;
      } catch (error) {}

      return null;
    },

    menuData() {
      return this.$store.state.shop.menu.data;
    },

    menuTabs() {
      return this.menuData?.TabBarDatas;
    },
  },
  methods: {
    hasSubCategory(item) {
      let hasSubCat = false;
      if (item.SubCategories) {
        if (item.SubCategories.length) {
          hasSubCat = true;
        }
      }

      return hasSubCat;
    },
    getRightItem(idCategory) {
      if (idCategory === 138) {
        return {
          title: 'Focus',
          items: [
            {
              text: this.$t('Le lin'),
              link: this.localePath('/le-lin-189'),
              image: require('assets/img/lin.jpg'),
            },
            {
              text: this.$t('Les essentiels'),
              link: this.localePath('/essentiels-179'),
              image: require('assets/img/essentiels.jpg'),
            },
          ],
        };
      } else if (idCategory === 180) {
        return {
          title: 'BEST SELLERS',
          items: [
            {
              text: this.$t('BEST SELLERS'),
              link: this.localePath('/meilleures-ventes-162'),
              image: require('assets/img/best-seller.jpg'),
            },
          ],
        };
      }
    },
    getSafeLink(link) {
      if (link?.includes('http')) {
        const parsedUrl = new URL(link); // Create a URL object
        return parsedUrl.pathname;
      } else {
        return this.localePath('/' + link);
      }
    },
  },
  watch: {
    menuOpen() {
      this.$emit('change', {
        state: this.menuOpen,
      });
    },
    $route(to, from) {
      this.menuOpen = false;
    },
  },
  mounted() {},
  components: {
    MenuIcon,
    XIcon,
    Teleport,
    ChevronLeftIcon,
    ChevronRightIcon,
  },
};
