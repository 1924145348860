
import { Button, Loading, Alert } from 'element-ui';
import BlockSlider from '@/components/BlockSlider.vue';
import CategoryFilters from '@/components/pages/category/CategoryFilters.vue';
import BlockText from '@/components/BlockText.vue';
import Banner from '@/components/Banner.vue';
import FooterApp from '@/components/FooterApp.vue';
import ProductCardDefaultMultiple from '@/components/ProductCard/ProductCardDefaultMultiple.vue';

import { LocalisationHelper } from '@/helpers/LocalisationHelper';

import Teleport from 'vue2-teleport';
// let page = 1;

function listToString(list) {
  if (list.length > 0) {
    let text = '';
    list.map((item, index) => {
      if (index !== 0) {
        text += ',';
      }
      text += item;
    });
    return text;
  } else {
    return null;
  }
}

function getIdCategory(permalink) {
  const regex = /(\d+)/;
  const match = permalink.match(regex);
  if (match) {
    return match[0];
  } else {
    return null;
  }
}

async function fetchCategory(store, { permalink, token }) {
  // const idCategory = getIdCategory(permalink);
  if (permalink) {
    await store.dispatch('newCategory/fetchCategory', {
      permalink,
      token,
    });
  }
}

async function fetchProducts(
  store,
  {
    permalink,
    idsAttribute = [],
    prices = [],
    promotion = false,
    page = 1,
    orderBy = null,
    token,
  }
) {
  // const idCategory = getIdCategory(permalink);
  if (permalink) {
    await store.dispatch('newCategory/fetchProducts', {
      idsAttribute,
      prices,
      page,
      orderBy,
      permalink,
      promotion,
      token,
    });
  }
}

async function fetchMoreProducts(
  store,
  { permalink, idsAttribute = [], prices = [], page, orderBy = null }
) {
  // const idCategory = getIdCategory(permalink);
  if (permalink) {
    await store.dispatch('newCategory/fetchMoreProducts', {
      permalink,
      idsAttribute,
      prices,
      page,
      orderBy,
    });
  }
}

function sortBy(val) {
  switch (val) {
    case 'price_asc':
      return {
        sortby: 'SortByPrice',
        value: 'ASC',
      };
    case 'price_desc':
      return {
        sortby: 'SortByPrice',
        value: 'DESC',
      };
    case 'newest_asc':
      return {
        sortby: 'SortByNewest',
        value: 'DESC',
      };
    case 'newest_desc':
      return {
        sortby: 'SortByNewest',
        value: 'ASC',
      };
    default:
      return {
        sortby: 'Default',
        value: 'DESC',
      };
  }
}

export default {
  layout: 'MainLayout',
  name: 'categoryPage',
  auth: false,

  head() {
    try {
      if (this.seo) {
        return {
          title: this.seo?.MetaTitle,
          meta: [
            {
              hid: 'description',
              name: 'description',
              content: this.seo.MetaDescription,
            },
            {
              hid: 'og:image',
              property: 'og:image',
              content: this.images ? this.images.category_medium.Src : '',
            },
          ],
        };
      }
    } catch (error) {}
    return {};
  },

  data() {
    // return {
    //   permalink,
    //   page,
    //   idsAttribute,
    //   prices,
    //   orderBy: sort,
    // };

    console.log('her data');

    const permalink = this.$route.params.cat;
    let page = 1;
    const query = this.$route.query;
    const attributesQuery = query.attributes;
    const pricesQuery = query.prices;
    const inPromotionQuery = query.promotion;
    const sortQuery = query.sort;
    const pageQuery = query.page;
    let idsAttribute = [];
    let prices = [];
    let sort = null;
    let promotion = false;

    if (attributesQuery) {
      idsAttribute = attributesQuery.split(',');
    }
    if (pricesQuery) {
      prices = pricesQuery.split(',');
    }
    if (inPromotionQuery === '1') {
      promotion = true;
    }
    if (sortQuery) {
      sort = sortBy(sortQuery);

      this.$store.commit('newCategory/setOrderBy', {
        value: sortQuery,
      });
    }
    if (pageQuery) {
      page = pageQuery;
    }

    this.$store.commit('newCategory/setFilters', {
      idsAttribute,
      prices,
      promotion,
    });

    return {
      isFirstLoading: false,
      permalink,
      page: page,
      idsAttribute,
      prices,
      promotion,
      orderBy: sort,
      limit: 31,
      isLodingMore: true,
      showLoadMore: true,
      token: this.$route.query.token,
      data: null,
      productsList: [],
    };
  },

  computed: {
    notFound() {
      return this.$store.state.newCategory.notFound;
    },
    notFoundMsg() {
      return this.$store.state.newCategory.error;
    },
    loading() {
      return this.$store.state.newCategory.loading;
    },

    hasNextPage() {
      return this.$store.state.newCategory.hasNextPage;
    },
    getData() {
      return this.$store.state.newCategory.currentCategory;
    },
    products() {
      return this.$store.state.newCategory.products;
    },
    nbrProduct() {
      return this.$store.state.newCategory.totalProduct;
    },
    categoryDiscription() {
      try {
        return this.getData?.Description;
      } catch (error) {}

      return {};
    },
    categoryTitle() {
      return this.categoryDiscription?.Title;
    },
    categoryDiscripton() {
      return this.categoryDiscription?.Description;
    },
    categoryDiscriptonShort() {
      return this.categoryDiscription?.DescriptionShort;
    },
    breadcrumbLinks() {
      return [
        {
          link: '/',
          text: this.$t('Home'),
        },
        {
          text: this.categoryTitle,
        },
      ];
    },
    seo() {
      try {
        let seo = this.getData?.SEO[0];
        if (this.$i18n.locale === 'en') {
          seo = this.getData?.SEO[1];
        }
        if (this.$i18n.locale === 'de') {
          seo = this.getData?.SEO[2];
        }

        return seo;
      } catch (error) {}
      return null;
    },
    images() {
      try {
        if (this.getData.Media.Image) {
          return this.getData.Media.Image;
        }
      } catch (error) {}

      return null;
    },
    videoUrl() {
      try {
        if (this.getData.Media.Video) {
          if (
            this.getData.Media.Video.Cover &&
            this.getData.Media.Video.CategoryVideoVimeoUrl
          ) {
            return this.getData.Media.Video.CategoryVideoVimeoUrl;
          }
        }
      } catch (error) {}
      return null;
    },

    filterPriceRange() {
      try {
        if (this.getData.Filters) {
          return this.getData.Filters.PriceRange;
        }
      } catch (error) {}
      return {};
    },
    filterSizes() {
      try {
        if (this.getData.Filters) {
          return this.getData.Filters.Sizes;
        }
      } catch (error) {}
      return [];
    },
    filterColors() {
      try {
        if (this.getData.Filters) {
          return this.getData.Filters.Colors;
        }
      } catch (error) {}
      return [];
    },

    showMobileSizes: {
      get() {
        return this.$store.state.popups.addToCartMobile.show;
      },
      set(value) {
        this.$store.commit('popups/addToCartMobile/removeProduct', value);
      },
    },
    productForAddMobile() {
      return this.$store.state.popups.addToCartMobile.product;
    },
    colorForAddMobile() {
      return this.$store.state.popups.addToCartMobile.color;
    },
  },

  methods: {
    async firstLoading() {
      this.isLodingMore = true;
      this.isFirstLoading = true;

      await fetchCategory(this.$store, {
        permalink: this.permalink,
        token: this.token,
      });

      //fixe le probleme de pagination (this.page === 1 || this.products.length === 0)
      if (this.page === 1 || this.products.length === 0) {
        await fetchProducts(this.$store, {
          permalink: this.permalink,
          idsAttribute: this.idsAttribute,
          prices: this.prices,
          promotion: this.promotion,
          page: this.page,
          orderBy: this.orderBy,
          token: this.token,
        });
      }
      this.isFirstLoading = false;
      this.isLodingMore = false;
    },

    async permalinkLangUrl() {
      const links = {};
      if (this.getData?.SEO) {
        this.getData?.SEO.map((item, index) => {
          links[item.LanguageIsoCode.toLowerCase()] = {
            cat: item.URL,
          };
        });
        await this.$store.dispatch('i18n/setRouteParams', links);
      }
    },
    getCardType(product, index) {
      // if (this.products) {
      //   if (this.products.length === 1) {
      //     return "one";
      //   }
      // }
      // if (product.ProductVideoCover) {
      //   return "video";
      // }
      // return index === 0 ? "full" : index === 4 ? "slider" : "one";
      if (index === 0) {
        return 'multiImage';
      } else if (index === 4) {
        return 'slider';
      } else {
        return 'image';
      }
    },
    getCardClassWrpper(index) {
      // if (this.products) {
      //   if (this.products.length === 1) {
      //     return "col-span-2 md:col-span-2 lg:col-span-1";
      //   }
      // }
      if (index === 0) {
        return 'col-span-2 lg:col-span-3';
      } else if (index === 4) {
        return 'col-span-4 md:col-span-2 row-span-1 lg:row-span-2';
      } else if (index !== 0 && index !== 4) {
        return 'col-span-2 md:col-span-2 lg:col-span-1 row-span-1';
      }
    },
    async loadMore() {
      this.page++;
      this.isLodingMore = true;
      await fetchMoreProducts(this.$store, {
        permalink: this.permalink,
        page: this.page,
        idsAttribute: this.idsAttribute,
        prices: this.prices,
        orderBy: this.orderBy,
      });
      let updatedQuery = { ...this.$route.query };
      updatedQuery.page = this.page;
      this.$router
        .replace({
          query: updatedQuery,
        })
        .catch(() => {});
      this.isLodingMore = false;
    },
    handleScroll() {
      if (!this.isLodingMore && this.hasNextPage) {
        if (this.$refs['loadMoreBtn']) {
          if (
            this.$refs['loadMoreBtn'].offsetTop <=
            window.scrollY + window.innerHeight + 992
          ) {
            this.loadMore();
          }
        }
      }
      if (this.showMobileSizes) {
        this.showMobileSizes = false;
      }
    },
    addToCart(size) {
      const cartHelper = new CartHelper(this.$http, this.$store, this.$auth);
      const item = {
        product: this.productForAddMobile,
        color: this.colorForAddMobile,
        size: size,
        quantity: 1,
      };

      if (this.$auth.loggedIn) {
        cartHelper.addProductToCart(item);
      } else {
        cartHelper.addProductToCartGuest(item);
      }
    },
    async filterHandler(event) {
      const orderBy = event.sort ? sortBy(event.sort) : null;

      this.idsAttribute = event.idsAttribute;
      this.prices = event.prices;
      this.orderBy = orderBy;
      this.page = 1;

      await fetchProducts(this.$store, {
        permalink: this.permalink,
        idsAttribute: event.idsAttribute,
        prices: event.prices,
        promotion: event.promotion,
        orderBy: orderBy,
        page: this.page,
      });

      const attributes = event.idsAttribute
        ? listToString(event.idsAttribute)
        : null;
      const prices = event.prices ? listToString(event.prices) : null;

      let updatedQuery = {};
      if (attributes) {
        updatedQuery.attributes = attributes;
      } else {
        let updatedQuery = { ...this.$route.query };
        delete updatedQuery.attributes;
      }
      if (prices) {
        updatedQuery.prices = prices;
      } else {
        let updatedQuery = { ...this.$route.query };
        delete updatedQuery.prices;
      }
      if (event.promotion === true) {
        updatedQuery.promotion = 1;
      } else {
        delete updatedQuery.promotion;
      }
      if (orderBy) {
        updatedQuery.sort = event.sort;
      } else {
        let updatedQuery = { ...this.$route.query };
        delete updatedQuery.sort;
      }
      delete updatedQuery.page;

      this.$router
        .replace({
          query: updatedQuery,
        })
        .catch(() => {});
    },
    getCategoryLink() {
      try {
        return this.seo.URL;
      } catch (error) {}
      return '#';
    },
  },

  created() {
    this.firstLoading();
    if (process.client) {
      window.addEventListener('scroll', this.handleScroll);
    }
  },

  destroyed() {
    if (process.client) {
      window.removeEventListener('scroll', this.handleScroll);
    }
  },

  async mounted() {
    console.log('her mounted');
    this.isLodingMore = false;
    if (process.client) {
      window.scrollTo(0, 0);
    }
    const LAST_CATEGORY_VISITED = 'LAST_CATEGORY_VISITED';

    this.$cookies.set(LAST_CATEGORY_VISITED, this.$route.path, {
      path: '/',
      maxAge: 60 * 60 * 24 * 7,
    });
    this.permalinkLangUrl();
  },
  watch: {},
  updated() {},
  components: {
    BlockSlider,
    BlockText,
    Banner,
    FooterApp,
    Button,
    Teleport,
    CategoryFilters,
    ProductCardDefaultMultiple,
    Alert,
  },
};
