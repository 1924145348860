
import { Button } from 'element-ui';
import { ProductHelpers } from '@/helpers/ProductHelpers.js';

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      productHelpers: new ProductHelpers(this.$http, this, this.$i18n.locale),
      categorySeo: [],
      ourSelectionProducts: [],
    };
  },
  computed: {
    mainCategorie() {
      return this.$store.state.shop.categories.mainCategorie;
    },
    idCategory() {
      const url = this.data?.cta?.url;
      if (url) {
        const match = url.match(/-(\d+)$/);
        return match ? match[1] : null;
      } else {
        return this.mainCategorie?.IdCategory;
      }
    },
  },
  methods: {
    getCategoryLink() {
      const seoCat = this.categorySeo;
      try {
        let seo = seoCat[0];
        if (this.$i18n.locale === 'en') {
          seo = seoCat[1];
        }
        if (this.$i18n.locale === 'de') {
          seo = seoCat[2];
        }
        return seo.URL;
      } catch (error) {}

      return 'product';
    },
    safeLink(link) {
      let url = this.localePath(link);
      if (link.includes('http')) {
        const listWords = link.split('/');
        url = '';
        for (let i = 3; i < listWords.length; i++) {
          const element = listWords[i];
          url += '/' + element;
        }
        return url;
      }
      if (link[0] !== '/') {
        url = this.localePath('/' + link);
      }

      return url;
    },
    getCategory() {
      try {
        if (this.mainCategorie) {
          this.productHelpers
            .categoryProducts({
              idCategory: this.idCategory,
              offset: 0,
              limit: 8,
              languageIsoCode: this.$i18n.locale,
              currencyIsoCode: this.$localisation.appCurrency.CurrencyIsoCode,
            })
            .then((data) => {
              if (data?.Products) {
                this.ourSelectionProducts = data?.Products;
              }
            });
        }
      } catch (error) {}
    },
  },
  async mounted() {
    this.getCategory();
  },
  watch: {
    mainCategorie(val) {
      if (val) {
        this.getCategory();
      }
    },
  },
  components: {
    Button,
  },
};
