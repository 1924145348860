
export default {
  layout: 'MainLayout',
  name: 'SearchPage',
  auth: false,
  head() {
    return {
      title: this.$t('home_title'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('home_meta_discription'),
        },
      ],
    };
  },
};
