
import { Button, Dialog } from 'element-ui';
import Teleport from 'vue2-teleport';
import { XIcon, CheckIcon } from 'vue-feather-icons';

const BROWSING_OUT = 'BROWSING_OUT';

export default {
  data() {
    return {
      open: false,
    };
  },
  mounted() {
    if (!this.$cookies.get(BROWSING_OUT)) {
      this.open = true;
    }
  },
  methods: {
    closeDialog() {
      this.$cookies.set(BROWSING_OUT, true, {
        path: '/',
        maxAge: 60 * 60 * 24 * 7,
      });
    },
    openLocalisationDialog() {
      this.open = false;
      this.$store.commit('shop/localisation/toggleDialog', true);
    },
  },
  components: { Teleport, Button, XIcon, Dialog, CheckIcon },
};
