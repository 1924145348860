
import Loading from '@/components/Loading.vue';
import Teleport from 'vue2-teleport';

// import { LocalisationHelper } from "@/helpers/LocalisationHelper";

export default {
  name: 'DefaultLayout',

  components: { Loading, Teleport },
  props: {
    menuTransparent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchIsOpen: false,
    };
  },
  // head() {
  //   return {
  //     link: [
  //       {
  //         rel: "stylesheet",
  //         href: "/vue-plyr.css",
  //       },
  //     ],
  //   };
  // },
  created() {
    // if (process.client) {
    //   this.searchIsOpen = false;
    //   const elBody = document.body;
    //   elBody.classList.remove("overflow-hidden");
    // }
  },
  methods: {
    openSearch() {
      if (process.client) {
        if (document) {
          this.searchIsOpen = true;
          const elBody = document.body;
          elBody.classList.add('overflow-hidden');
        }
      }
      const fieldSearch =
        this.$refs['blocksearch'].$refs['field-search'].$el.querySelector(
          'input'
        );
      fieldSearch.focus();
    },
    closeSearch() {
      if (process.client) {
        if (document) {
          this.searchIsOpen = false;
          const elBody = document.body;
          elBody.classList.remove('overflow-hidden');
        }
      }
    },
    setIsMobile() {
      this.$store.commit('shared/wind/toggle');
    },
  },

  computed: {
    getIsMobile() {
      return this.$store.state.shared.wind.isMobile;
    },
    isLoading() {
      return this.$store.state.shared.wind.loading;
    },
    loading() {
      return this.$store.state.shared.wind.loading;
    },
    hasCountryOut() {
      return process.env.HAS_COUNTRY_OUT;
    },
  },

  async mounted() {
    this.setIsMobile();
    window.addEventListener('resize', () => {
      this.setIsMobile();
    });
  },
  watch: {
    $route(to, from) {
      // Update currentRoute when route changes
      this.$store.commit('shared/wind/toggleAbandonedVisit', false);
    },
  },
};
