
import { LookBooksQuery } from '~/graphql/queries/LookBooks';
import LookbookCard from './components/LookbookCard.vue';
import { ProductHelpers } from '@/helpers/ProductHelpers';

function getProductIds(lookBooks) {
  let elements = [];

  if (lookBooks !== null) {
    elements = lookBooks.data[0].attributes.content;
  }

  const list = [];
  elements.map((item) => {
    if (item.__typename === 'ComponentLookBookLookBookItem') {
      item.products.map((item2) => {
        if (!list.includes(item2.product_url)) {
          list.push(item2.product_url);
        }
      });
    } else if (item.__typename === 'ComponentLookBookLookBookCols') {
      item.products_left.map((item2) => {
        if (!list.includes(item2.product_url)) {
          list.push(item2.product_url);
        }
      });
      item.products_right.map((item2) => {
        if (!list.includes(item2.product_url)) {
          list.push(item2.product_url);
        }
      });
    }
  });

  const listString = list.reduce((res, item) => res + item + ',', '');
  return listString.substring(0, listString.length - 1);
}

export default {
  layout: 'MainLayout',
  name: 'LookBookPage',
  auth: false,

  async asyncData(context) {
    const { app, store, query, i18n, route } = context;

    const res = await app.apolloProvider.defaultClient.query({
      query: LookBooksQuery,
      variables: {
        lang: i18n.locale,
        permalink: route.params.permalink,
      },
    });

    const lookBooks = res.data.lookBooks;

    if (lookBooks.data.length === 0) {
      // Trigger a 404 error if no lookBooks are found
      context.error({ statusCode: 404, message: 'LookBook not found' });
      return; // Ensure the function stops execution after throwing the error
    }

    const lb = lookBooks.data[0].attributes;
    const links = {};
    links[lb.locale] = { permalink: lb.seo.permalink };

    lb.localizations.data.map((item) => {
      links[item.attributes.locale] = {
        permalink: item.attributes.seo.permalink,
      };
    });

    await store.dispatch('i18n/setRouteParams', links);

    return {
      lookBooks,
    };
  },

  data() {
    return {
      products: [],
    };
  },
  computed: {
    pageElements() {
      // return this.lookBooks.data;
      if (this.lookBooks === null) {
        return [];
      }
      return this.lookBooks.data[0].attributes.content;
    },
  },
  async mounted() {
    if (this.lookBooks) {
      const productHelpers = new ProductHelpers(
        this.$http,
        this,
        this.$i18n.locale
      );

      const ids = getProductIds(this.lookBooks);
      try {
        this.products = await productHelpers.getProductsWithListIds({
          IdProductList: ids,
          LanguageIsoCode: this.$i18n.locale,
        });
      } catch (error) {}
    }
  },
  methods: {
    getFullImgCardImageUrl(val) {
      if (val.image.data) {
        return val.image.data.attributes.url;
      }
      return '';
    },
    setPermalinks() {},
  },
  components: { LookbookCard },
  watch: {
    pageElements(to, from) {
      if (to.length) {
        this.setPermalinks();
      }
    },
  },
};
